<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_working_time')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>
            <!--<b-card-header class="pb-50">-->
            <!--<h5 class="text-primary">{{$t('label_undecided_mail')}}</h5>-->
            <!--</b-card-header>-->
            <b-card-body>
                <b-row>

                    <b-col cols="12">
                        <b-row class="mb-1">
                            <b-col
                                    cols="12"
                                    md="3"
                                    class=" mb-1 date-range-col"
                            >
                                <label>{{ $t('label_date') }}</label>

                                <div class="date-range-filter">
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.executed_date_from"
                                            @input="filterData.executed_date_from = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                    <div class="date-separator"/>

                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.executed_date_to"
                                            @input="filterData.executed_date_to = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>
                                </div>

                                <!--</b-form-group>-->
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_deal_number')}}</label>
                                <!--:query-params="{participant_type:'recipient'}"-->
                                <infinity-scroll
                                        v-model="filterData.deal_id"
                                        :multiple="false"
                                        primary-key="deal_id"
                                        url="/deal"
                                        selectedProp="deal_id"
                                        :placeholder="$t('label_deal_number')"

                                >
                                    <template #label="{item}">{{ item.deal_number }}</template>

                                </infinity-scroll>
                            </b-col>

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_user')}}</label>
                                <!--:query-params="{participant_type:'recipient'}"-->
                                <infinity-scroll
                                        v-model="filterData.created_user_id"
                                        :multiple="false"
                                        primary-key="id"
                                        url="/user"
                                        selectedProp="id"
                                        :placeholder="$t('label_user')"

                                >
                                    <template #label="{item}">{{ item.name }}</template>

                                </infinity-scroll>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_type_of_activity')}}</label>
                                <v-select
                                        class="sort--select form-control"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.declarative_time"
                                        :options="workingTypes"
                                        label="label"
                                        :placeholder="$t('label_type_of_activity')"
                                        :reduce="val => val.value"
                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ $t(option.label) }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span >{{ $t(option.label) }}</span>
                                    </template>

                                </v-select>
                            </b-col>

                            <b-col
                                    cols="12"
                                    md="3"
                                    class="mb-md-0 mb-2 mt-1"
                            >

                                <b-form-checkbox
                                        id="ref-link-filter"
                                        value="1"
                                        unchecked-value="0"
                                        v-model="filterData.show_not_assigned_to_deal"
                                >
                                    <label for="ref-link-filter">{{ $t('label_show_not_assigned_worktime') }}</label>
                                </b-form-checkbox>


                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card

                class="mb-0"
        >

            <workingtime-tab :show-all="true" :extra-filter-data="filterData"></workingtime-tab>

        </b-card>


    </div>
</template>

<script>
    import {
        BCard,  BFormInput, BButton, BCardBody, BCardHeader, BFormCheckbox, BTooltip, BFormRadio
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import infinityScroll from '@/views/components/infinityScroll'

    import workingtimeTab from '../deal/tabs/tabWorkingTimeReport'


    import {
        DEAL_PREFIX as MODULE_PREFIX,
        WORKING_TIME_PREFIX as PREFIX,
        workTimeTables as tableTypes,
        workingTypes,
        calcTypes
    } from './../deal/moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BCardBody,
            BFormCheckbox,
            BFormRadio,
            BCardHeader,
            BTooltip,
            // columnChooser,
            vSelect,
            infinityScroll,
            workingtimeTab
        },

        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                filterData: {
                    executed_date_from: '',
                    executed_date_to: '',
                    deal_id: '',
                    // solicitor_id: '',
                    declarative_time: '',
                    calc_type: null,
                    created_user_id: null,
                    show_not_assigned_to_deal:0
                },

                workingTypes,

            }
        },

        watch: {

        },

        created() {

        },


        methods: {

        },

    }
</script>

